import { State as CobrandingState } from '../reducers/cobranding-reducer';
import * as queryString from 'query-string';
import { MicrobrandingParams, URLParameters } from '../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../stores/redux.store';
import { Location as HistoryLocation } from 'history';

export type Application =
  | 'ace'
  | 'access'
  | 'admin'
  | 'atlas'
  | 'bitbucket'
  | 'cac'
  | 'compass'
  | 'confluence'
  | 'dac'
  | 'experts'
  | 'itap-uat-backend'
  | 'jac'
  | 'jira'
  | 'loom'
  | 'jira-align'
  | 'megamanzero'
  | 'mac'
  | 'mpac'
  | 'ondemand'
  | 'opsgenie'
  | 'pollinator' // for functional testing
  | 'profile'
  | 'manage-profile'
  | 'sac'
  | 'sourcetree'
  | 'start'
  | 'statuspage'
  | 'teamcentral'
  | 'trello'
  | 'uac'
  | 'wac';

export type ApplicationData = {
  application: Application;
  experience?: string;
} & ApplicationParams;

export interface ApplicationParams {
  name: string;
  useInLoginPrompt?: boolean;
}

export type ApplicationsDictionary = { [P in Application]: ApplicationParams };

export const applications: ApplicationsDictionary = {
  admin: {
    name: 'Adminhub',
    useInLoginPrompt: false,
  },
  access: {
    name: 'Adminhub',
    useInLoginPrompt: false,
  },
  ace: {
    name: 'Atlassian Community Events',
    useInLoginPrompt: false,
  },
  atlas: {
    name: 'Atlas',
    useInLoginPrompt: true,
  },
  bitbucket: {
    name: 'Bitbucket',
    useInLoginPrompt: true,
  },
  cac: {
    name: 'Documentation',
    useInLoginPrompt: true,
  },
  dac: {
    name: 'Developer Portal',
    useInLoginPrompt: true,
  },
  experts: {
    name: 'Partner Program',
    useInLoginPrompt: true,
  },
  'itap-uat-backend': {
    name: 'itap-uat-backend',
    useInLoginPrompt: false,
  },
  jac: {
    name: 'Atlassian Bug Reporting & Feature Requests',
  },
  mpac: {
    name: 'Marketplace',
    useInLoginPrompt: true,
  },
  profile: {
    name: 'Atlassian',
  },
  'manage-profile': {
    name: 'Atlassian account',
    useInLoginPrompt: false,
  },
  sac: {
    name: 'Support',
    useInLoginPrompt: true,
  },
  teamcentral: {
    name: 'Team Central',
    useInLoginPrompt: false,
  },
  trello: {
    name: 'Trello',
    useInLoginPrompt: true,
  },
  mac: {
    name: 'My Atlassian',
    useInLoginPrompt: true,
  },
  jira: {
    name: 'Jira',
    useInLoginPrompt: true,
  },
  loom: {
    name: 'Loom',
    useInLoginPrompt: true,
  },
  'jira-align': {
    name: 'Jira Align',
    useInLoginPrompt: true,
  },
  confluence: {
    name: 'Confluence',
    useInLoginPrompt: true,
  },
  ondemand: {
    name: 'Atlassian Cloud',
    useInLoginPrompt: false,
  },
  opsgenie: {
    name: 'Opsgenie',
    useInLoginPrompt: true,
  },
  start: {
    name: 'Start',
    useInLoginPrompt: false,
  },
  megamanzero: {
    name: 'MegamanZero',
    useInLoginPrompt: false,
  },
  pollinator: {
    // for functional testing
    name: 'Pollinator',
    useInLoginPrompt: false,
  },
  wac: {
    name: 'Atlassian',
    useInLoginPrompt: false,
  },
  compass: {
    name: 'Compass',
    useInLoginPrompt: true,
  },
  sourcetree: {
    name: 'Sourcetree',
    useInLoginPrompt: false,
  },
  statuspage: {
    name: 'Statuspage',
  },
  uac: {
    name: 'UAC',
    useInLoginPrompt: false,
  },
};

export const applicationSelector = createSelector(
  (state: RootState) => state.cobranding,
  cobranding => lookupApplication(cobranding)
);

export function lookupApplication(cobranding: CobrandingState): ApplicationData | null {
  const applicationFromContinue = applicationFromContinueUrl();
  const {
    application: applicationFromQueryParameter,
    experience: experienceFromQueryParameter,
  } = applicationFromApplicationQueryParameter();
  const application =
    cobranding.derivedApplication || applicationFromContinue || applicationFromQueryParameter;
  const experience = cobranding.experience || experienceFromQueryParameter;

  if (application) {
    const appData = applications[application];
    if (appData) {
      return {
        application,
        experience,
        ...appData,
      };
    }
  }

  return null;
}

export const cobrandingURLParams = (
  location: Location | HistoryLocation,
  productHint: string | undefined
) => {
  const params: URLParameters = queryString.parse(location.search);
  const allParams =
    productHint !== undefined && productHint !== null
      ? { ...params, productHint: productHint }
      : { ...params };

  const filteredCobrandingParams = Object.keys(allParams)
    .filter(key => ['continue', 'application', 'display', 'productHint'].includes(key))
    .reduce((o, key) => {
      o[key] = allParams[key];
      return o;
    }, {});

  const cobrandingURLParams =
    filteredCobrandingParams && Object.keys(filteredCobrandingParams).length
      ? { ...filteredCobrandingParams }
      : '';
  return cobrandingURLParams;
};

/*
 * We should kill it
 */
export function getMicrobrandingFromUrl(location: Location | HistoryLocation): MicrobrandingParams {
  const params: URLParameters = queryString.parse(location.search);
  return Object.keys(params)
    .filter(key => ['application', 'continue', 'tenant'].includes(key))
    .reduce((o, key) => {
      o[key] = params[key];
      return o;
    }, {});
}

/**
 * Exported for testing only.
 *
 * Paths that don't look like they belong to Jira (or Confluence) on *.atlassian.net.
 * https://stash-atlassian-com.jira.btpn.skyfencenet.com/projects/EDGE/repos/atlassian-proxy/browse/nginx/lua/endpoints.lua#271
 */
export const NON_JIRA_PATHS = [
  '/admin',
  '/login',
  '/logout',
  '/impersonate',
  '/home',
  '/people',
  '/trusted-admin',
];

/**
 * Determine the cobranding application from the `?continue=...` URL param as a last resort.
 *
 * **This code should _only_ be relied upon for situations where passing an `?application=...`
 * param or product hint is not possible**, such as within Slack's direct initiate flow. In OIDC
 * flows applications should pass a product hint, and in all other flows applications should pass
 * an `?application=...` param to id.a.c.
 */
function applicationFromContinueUrl(): Application | null {
  const params: URLParameters = queryString.parse(window.location.search);
  const continueParam = params.continue;

  if (!continueParam) return null;

  try {
    const continueUrl = new URL(continueParam, window.location.origin);
    const { hostname, pathname, searchParams } = continueUrl;

    if (['bitbucket-org.jira.btpn.skyfencenet.com', 'integration.bb-inf.net'].includes(hostname)) {
      return 'bitbucket';
    } else if (['.opsgenie.com', '.opsgeni.us'].some(domain => hostname.endsWith(domain))) {
      return 'opsgenie';
    } else if (['manage.statuspage.io', 'manage.statuspagestaging.com'].includes(hostname)) {
      return 'statuspage';
    } else if (['trello-com.jira.btpn.skyfencenet.com', 'trellis.coffee'].includes(hostname)) {
      return 'trello';
    } else if (
      ['atlassian-com.jira.btpn.skyfencenet.com', 'www-atlassian-com.jira.btpn.skyfencenet.com', 'wac-stg-internal-atlassian-com.jira.btpn.skyfencenet.com'].includes(hostname)
    ) {
      return 'wac';
    } else if (['team-atlassian-com.jira.btpn.skyfencenet.com', 'team-stg-atlassian-com.jira.btpn.skyfencenet.com'].includes(hostname)) {
      return 'atlas';
    } else if (
      ['.atlassian.net', 'atlassian-fex.net', '.jira-dev.com'].some(domain =>
        hostname.endsWith(domain)
      )
    ) {
      const unwrappedContinueUrl =
        pathname === '/login' && searchParams.get('dest-url')
          ? new URL(searchParams.get('dest-url')!, continueUrl)
          : continueUrl;
      const { pathname: unwrappedPathname } = unwrappedContinueUrl;

      if (unwrappedPathname === '/wiki' || unwrappedPathname.startsWith('/wiki/')) {
        return 'confluence';
      } else if (unwrappedPathname === '/compass' || unwrappedPathname.startsWith('/compass/')) {
        return 'compass';
      } else if (
        // Root could be Jira or Conflience
        unwrappedPathname !== '/' &&
        !NON_JIRA_PATHS.some(
          prefix => unwrappedPathname === prefix || unwrappedPathname.startsWith(`${prefix}/`)
        )
      ) {
        return 'jira';
      }
    }
  } catch (error) {
    // Malformed URL, ignore
  }

  return null;
}

function applicationFromApplicationQueryParameter(): {
  application: string | null;
  experience: string | null;
} {
  try {
    const [application, experience] =
      new URLSearchParams(window.location.search).get('application')?.split('--') || [];
    if (application && isValidApplication(application)) {
      return { application, experience };
    }
  } catch (error) {
    // Malformed query parameter, ignore
  }
  return { application: null, experience: null };
}

export function isTrelloDesktop(): boolean {
  const userAgent = window.navigator.userAgent;
  return userAgent.includes('TrelloDesktop');
}

export const getApplicationName = (app?: Application) =>
  app && applications[app] ? applications[app].name : 'Atlassian';

const isValidApplication = (application: string): application is Application =>
  Object.keys(applications).includes(application);
