import deprecationWarning from '@atlaskit/ds-lib/deprecation-warning';
import { B200, N30A, skeleton as skeletonColor } from './colors';
export var CHANNEL = '__ATLASKIT_THEME__';
export var DEFAULT_THEME_MODE = 'light';
export var THEME_MODES = ['light', 'dark'];

/*
  These theme values are expressed as functions so that if we decide to make
  them dependent on props in the future, it wouldn't require a significant
  refactor everywhere they are being used.
*/
/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated Use `token('border.radius', '3px')` instead.
 */
export var borderRadius = function borderRadius() {
  return 3;
};
/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated Use `token('space.100', '8px')` instead with any of the space tokens.
 * Available space tokens can be found on {@link https://atlassian.design/foundations/spacing/#scale}
 */
export var gridSize = function gridSize() {
  return 8;
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated Use token('font.body') or Text/Heading components instead.
 * Font tokens {@link https://atlassian.design/components/tokens/all-tokens#font}
 * Heading component {@link https://atlassian.design/components/heading/examples}
 * Text component {@link https://atlassian.design/components/primitives/text/examples}
 */
export var fontSize = function fontSize() {
  return 14;
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated Use `token('font.body.small')` or Text/Heading components instead.
 * Font tokens {@link https://atlassian.design/components/tokens/all-tokens#font}
 * Heading component {@link https://atlassian.design/components/heading/examples}
 * Text component {@link https://atlassian.design/components/primitives/text/examples}
 */
export var fontSizeSmall = function fontSizeSmall() {
  return 11;
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated Use `token('font.family.body') or consider the Text/Heading components instead.
 * Font family tokens {@link https://atlassian.design/components/tokens/all-tokens#font-family}
 * Heading component {@link https://atlassian.design/components/heading/examples}
 * Text component {@link https://atlassian.design/components/primitives/text/examples}
 */
export var fontFamily = function fontFamily() {
  return "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated Use `token('font.family.code`) instead.
 * Font family tokens {@link https://atlassian.design/components/tokens/all-tokens#font-family}
 */
export var codeFontFamily = function codeFontFamily() {
  return "'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace";
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * Please use `@atlaskit/focus-ring` instead.
 */
export var focusRing = function focusRing() {
  var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "var(--ds-border-focused, ".concat(B200, ")");
  var outlineWidth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : gridSize() / 4;
  deprecationWarning('@atlaskit/theme', 'focus ring mixin', 'Please use `@atlaskit/focus-ring` instead.');
  return "\n  &:focus {\n    outline: none;\n    box-shadow: 0px 0px 0px ".concat(outlineWidth, "px ").concat(color, ";\n  }\n");
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * Please use `@atlaskit/focus-ring` instead.
 */
export var noFocusRing = function noFocusRing() {
  return "\n  box-shadow: none;\n";
};
export var layers = {
  card: function card() {
    return 100;
  },
  navigation: function navigation() {
    return 200;
  },
  dialog: function dialog() {
    return 300;
  },
  layer: function layer() {
    return 400;
  },
  blanket: function blanket() {
    return 500;
  },
  modal: function modal() {
    return 510;
  },
  flag: function flag() {
    return 600;
  },
  spotlight: function spotlight() {
    return 700;
  },
  tooltip: function tooltip() {
    return 9999;
  }
};

// eslint-disable-next-line @atlaskit/design-system/use-visually-hidden
/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * Please use `@atlaskit/visually-hidden`
 */
export var visuallyHidden = function visuallyHidden() {
  deprecationWarning('@atlaskit/theme', 'visually hidden mixin', 'Please use `@atlaskit/visually-hidden` instead.');
  return {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    height: '1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  };
};

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * Please use `@atlaskit/visually-hidden`
 */
export var assistive = visuallyHidden;

/**
 * These styles are mirrored in:
 * packages/design-system/menu/src/internal/components/skeleton-shimmer.tsx
 *
 * Please update both.
 */
export var skeletonShimmer = function skeletonShimmer() {
  return {
    css: {
      backgroundColor: "var(--ds-skeleton, ".concat(skeletonColor(), ")"),
      animationDuration: '1.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      animationDirection: 'alternate'
    },
    keyframes: {
      from: {
        backgroundColor: "var(--ds-skeleton, ".concat(skeletonColor(), ")")
      },
      to: {
        backgroundColor: "var(--ds-skeleton-subtle, ".concat(N30A, ")")
      }
    }
  };
};