import { colors } from '@atlaskit/theme';

/**
 * https://bitbucket-org.jira.btpn.skyfencenet.com/atlassian/atlaskit-mk-2/src/master/packages/bitbucket/codemod-util-shared-styles-to-theme/
akColorPrimary1	string	colors.N800	string
akColorPrimary2	string	colors.B500	string
akColorPrimary3	string	colors.N0	string
akColorSecondary1	string	colors.R300	string
akColorSecondary2	string	colors.Y300	string
akColorSecondary3	string	colors.G300	string
akColorSecondary4	string	colors.P300	string
akColorSecondary5	string	colors.T300	string
 */
export const colorPalette = {
  primaryNavy: colors.N800,
  primaryBlue: colors.B500,
  primaryWhite: colors.N0,
  secondaryRed: colors.R300,
  secondaryYellow: colors.Y300,
  secondaryGreen: colors.G300,
  secondaryCyan: colors.P300,
  secondaryPurple: colors.T300,
};
