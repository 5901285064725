import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApplicationDestination } from '../selectors/application-destination';
import ButtonLink from './ButtonLink';

import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';

const SubtleLink = styled.a`
  color: ${colors.N800};

  &:hover {
    color: ${colors.N800};
  }
`;

export const SELF_SERVICE_LINK_URL =
  'https://support-atlassian-com.jira.btpn.skyfencenet.com/atlassian-account/docs/troubleshoot-login-issues-with-your-atlassian-account/';

export function SelfServiceLink(props: { onClick?: () => void }) {
  return (
    <SubtleLink
      href={SELF_SERVICE_LINK_URL}
      id="self-service-link"
      target="_blank"
      rel="noopener noreferrer"
      // https://mathiasbynens.github.io/rel-noopener
      onClick={props.onClick}>
      <FormattedMessage id="resetpassword.self.service" defaultMessage="Login help" />
    </SubtleLink>
  );
}

export const SUPPORT_LINK_URL = 'https://support-atlassian-com.jira.btpn.skyfencenet.com/';

export function SupportLink(props: { onClick?: () => void }) {
  return (
    <SubtleLink
      href={SUPPORT_LINK_URL}
      id="support-link"
      target="_blank"
      rel="noopener noreferrer"
      // https://mathiasbynens.github.io/rel-noopener
      onClick={props.onClick}>
      <FormattedMessage id="resetpassword.support" defaultMessage="Contact Support" />
    </SubtleLink>
  );
}

// Used for both signup and login flows
export const PRIVACY_POLICY_URL_MOBILE = 'https://www-atlassian-com.jira.btpn.skyfencenet.com/legal/privacy-policy/mobile';
export const PRIVACY_POLICY_URL = 'https://www-atlassian-com.jira.btpn.skyfencenet.com/legal/privacy-policy';

export function PrivacyPolicyLink(props: { isMobileLink: boolean }) {
  const privacyPolicyUrl = props.isMobileLink ? PRIVACY_POLICY_URL_MOBILE : PRIVACY_POLICY_URL;

  return (
    <SubtleLink
      href={privacyPolicyUrl}
      target="_blank"
      rel="noreferrer noopener"
      // https://mathiasbynens.github.io/rel-noopener
    >
      <FormattedMessage id="common.privacy.policy" defaultMessage="Privacy Policy" />
    </SubtleLink>
  );
}

// Used for signup flows only
export const CLOUD_TERMS_OF_SERVICE_URL_MOBILE =
  'https://www-atlassian-com.jira.btpn.skyfencenet.com/legal/customer-agreement/mobile';
export const CLOUD_TERMS_OF_SERVICE_URL = 'https://www-atlassian-com.jira.btpn.skyfencenet.com/legal/cloud-terms-of-service';

// Used for login flows only
export const USER_NOTICE_URL_MOBILE = 'https://www-atlassian-com.jira.btpn.skyfencenet.com/legal/user-notice/mobile';
export const USER_NOTICE_URL = 'https://www-atlassian-com.jira.btpn.skyfencenet.com/legal/user-notice';

export function UserNoticeLink(props: { isMobileLink: boolean }) {
  const userNoticeUrl = props.isMobileLink ? USER_NOTICE_URL_MOBILE : USER_NOTICE_URL;

  return (
    <SubtleLink
      href={userNoticeUrl}
      target="_blank"
      rel="noreferrer noopener"
      // https://mathiasbynens.github.io/rel-noopener
    >
      <FormattedMessage
        id="common.user.notice"
        defaultMessage="User Notice"
        description="Link that takes users to the Atlassian User Notice"
      />
    </SubtleLink>
  );
}

export function LearnMoreAboutAtlassianAccountLink(props: { onClick?: () => void }) {
  const { formatMessage } = useIntl();
  return (
    <SubtleLink
      id="atlassian-account-link-more-short"
      target="_blank"
      rel="noreferrer noopener"
      onClick={props.onClick}
      aria-label={formatMessage({
        id: 'cobranding.footer.learn.more.about.atlassian.account.link.aria.label',
        defaultMessage: 'Learn more about Atlassian Account',
        description:
          'Aria label for short link to let users learn more about what an Atlassian Account is.',
      })}
      href="https://support-atlassian-com.jira.btpn.skyfencenet.com/atlassian-account/docs/what-is-an-atlassian-account/">
      <FormattedMessage
        id="cobranding.footer.learn.more.about.atlassian.account.link"
        defaultMessage="more"
        description="Short link to let users learn more about using one account for multiple products. e.g. One account for {products} and more."
      />
    </SubtleLink>
  );
}

export function RequestAccessLink(props: {
  onClick?: () => void;
  destUrl: string;
  applicationDestination: ApplicationDestination;
}) {
  const { destUrl, applicationDestination } = props;
  let redirectUrl = `https://${
    applicationDestination.destinationHostname
  }/login?application=${applicationDestination.applicationName.toLowerCase()}&permission-violation=true&preferred-redirect=request-access`;
  if (destUrl) {
    redirectUrl += `&dest-url=${encodeURIComponent(destUrl)}`;
  }
  const buttonText = (
    <FormattedMessage
      id="select.account.request.access.to.product"
      defaultMessage="Request access to {productName}"
      values={{ productName: applicationDestination.applicationName }}
    />
  );
  return (
    <ButtonLink id="redirect-to-request-access" href={redirectUrl} onClick={props.onClick}>
      {buttonText}
    </ButtonLink>
  );
}

export const PASSWORD_LEAKED_LINK =
  'https://support-atlassian-com.jira.btpn.skyfencenet.com/atlassian-account/docs/protect-your-atlassian-account/';
