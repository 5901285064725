import { browserMetrics } from '@atlassian/browser-metrics';
import { BMEventsType, BrowserMetricsConfig } from '@atlassian/browser-metrics/types';
import { version } from 'react';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { currentEnvType } from '../utilities/env';
import { FeatureFlagId, isBooleanFeatureFlagSet } from '../utilities/feature-flags';

export const startBrowserMetrics = (analyticsClient: AnalyticsClient) => {
  const info: BrowserMetricsConfig['info'] = {
    app: {
      version: { web: process.env.REACT_APP_VERSION ?? 'no-version' },
      framework: { name: 'react', version },
    },
    product: 'identity',
    // Region is used for "Split by" in Perf Portal.
    region: 'unknown',
  };

  const endpoints: BrowserMetricsConfig['endpoints'] = {
    eventPipelineClient: Promise.resolve(analyticsClient.getInstance()),
  };

  const events: BrowserMetricsConfig['events'] = {
    [BMEventsType.WEB_VITALS]: {
      enabled: true,
    },
  };

  const plugins: BrowserMetricsConfig['plugins'] = {
    featureFlags: {
      client: Promise.resolve({
        getValue: (flag: FeatureFlagId) => isBooleanFeatureFlagSet(flag, false),
      }),
    },
    resourceTimings: {
      /**
       * Browser Metrics track all the network calls and add them automatically to the performance events.
       * To ensure we're not recording any UGC / PII, we need to provide a sanitise method.
       */
      sanitiseEndpoints: url => {
        // Remove sensitive PII data (i.e. all URL params)
        return url.split('?')[0];
      },
      /**
       * Takes a URL and removes hashes, cache busting parameters, versioning, and other unique identifiers specific to the build of the app.
       * This is so that URLs that exist as a part of a browser metrics payload will not be deemed unique due to having different hashes.
       *
       * * For example, when `url` is:
       * - `https://aid--frontend-prod-atl--paas-net.jira.btpn.skyfencenet.com/atlassian-id/front-end/5.0.449/static/js/login.28848bff.chunk.js`
       * - `https://aid--frontend-prod-atl--paas-net.jira.btpn.skyfencenet.com/atlassian-id/front-end/5.0.450/static/js/login.66eb7699.chunk.js`
       *
       * Then both of the above should be cleaned to `login.js`, which will be treated as the same URL for reporting purposes.
       */
      mapResources: url => {
        // url = https://aid--frontend-prod-atl--paas-net.jira.btpn.skyfencenet.com/.../static/js/login.28848bff.chunk.js

        if (!url.includes('aid-frontend')) {
          // This is an external URL where we can't gurantee the URL structure, therefore cannot do any mapping.
          return url;
        }

        // hashedFileName = login.28848bff.chunk.js
        const hashedFileName = url.split('/').pop() ?? 'unknown';

        const parts = hashedFileName.split('.');
        const ext = parts.length > 1 ? parts.pop() : 'unknown';

        // Returning login.js
        return `${parts[0]}.${ext}`;
      },
      // If url supports TimingAllowOrigin header, then BM calculates cache status of static resources:
      hasTimingHeaders: () => false,
    },
  };

  browserMetrics.init({
    info,
    endpoints,
    plugins,
    events,
    debug: currentEnvType !== 'prod',
  });

  browserMetrics.startPageLoad({ isInitial: true });
};
