/**
 * These are the default parameters for LogoProps if the user does not specify values.
 */
export var defaultLogoParams = {
  iconColor: 'inherit',
  iconGradientStart: 'inherit',
  iconGradientStop: 'inherit',
  label: '',
  size: 'medium',
  textColor: 'currentColor'
};

/**
 * The props for the <Wrapper /> that takes the svg and turns it into a component.
 */

export var sizes = {
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 40,
  xlarge: 48
};

/**
 * In order to pass linting rules, these props were renamed to be more descriptive i.e. props renamed to LogoProps and
 * defaultParams to defaultLogoParams. However, this is a breaking change as it is a file with public entry points. The code
 * below is here to keep Logo backwards compatible.
 * See the ticket here: https://product--fabric-atlassian-net.jira.btpn.skyfencenet.com/browse/DSP-4086.
 *
 * Note that some consumers are accessing this using like so: import { Props } from '@atlaskit/logo/constants', this should
 * still work despite `LogoProps` being in a different types.tsx file.
 *
 */

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-175 Internal documentation for deprecation (no external access)} This has been renamed, please import `LogoProps` instead.
 */
// eslint-disable-next-line @repo/internal/react/consistent-types-definitions

/**
 * @deprecated {@link https://hello-atlassian-net.jira.btpn.skyfencenet.com/browse/ENGHEALTH-176 Internal documentation for deprecation (no external access)} This has been renamed, please import `defaultLogoParams` instead.
 */
export var DefaultProps = defaultLogoParams;